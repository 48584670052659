@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $accent: mat.get-color-from-palette($accent-palette, 500);

  // Global
  .mat-mdc-card {
    //padding: 1.25rem;

    .mat-mdc-card-content {
      padding: 1.5rem;
    }

    // Avatar Img
    .card-header-image {
      background-size: cover;
    }

    //  Main Img
    .mdc-card__media {
      object-fit: cover; // Allow img to be always responsive
    }
  }

  .mat-mdc-card.card-img {
    padding: 0;
  }

  .mat-primary-card {
    background-color: $primary !important;
  }

  .mat-primary-glass-card {
    background-color: color-mix(in srgb, $primary 30%, transparent) !important;
  }

  .mat-accent-glass-card {
    background-color: color-mix(in srgb, $accent 30%, transparent) !important;
  }

  // Custom Class - Glass Morphism
  .light .glass-card {
    background: rgba(255, 255, 255, 0.3);
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: inset 0 0 0 0px #fff,
      inset 0 0 0 calc(1px + 0px) hsla(0, 0%, 100%, 0.1), var(0, 0 0 #0000);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
  }

  .dark .glass-card {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: inset 0 0 0 0px #fff,
      inset 0 0 0 calc(1px + 0px) hsla(0, 0%, 100%, 0.1), var(0, 0 0 #0000);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
  }

  // Safari Support
  @supports (-webkit-hyphens:none) {
    .glass-card .glass-card {
      -webkit-backdrop-filter: blur(0px);
    }
  }

  // Light Theme
  .light {
    .mat-mdc-card {
      // border: 1px solid #eaecf0;
      // border: 1px solid #eaecf0 !important;
      // border: 1px solid rgba(0, 0, 0, 0.2) !important;
      box-shadow: none !important;
      border-radius: 8px;
    }

    // Custom Class - Glass Morphism
    .glass-card-primary {
      background: rgba($primary, 0.1);
      color: $primary;
      border: 1px solid rgba($primary, 0.2) !important;
      box-shadow: inset 0 0 0 0px #fff,
        inset 0 0 0 calc(1px + 0px) hsla(0, 0%, 100%, 0.1), var(0, 0 0 #0000);
    }
  }

  // Dark Theme
  .dark {
    .mat-mdc-card {
      // border: 0;
      // box-shadow: inset 0 0 0 1px #ffffff1a !important;
      box-shadow: none !important;
      border-radius: 8px;
    }

    // Custom Class - Glass Morphism
    .glass-card-primary {
      background: rgba($primary, 0.1);
      color: var(--mat-sidenav-content-text-color);
      border: 1px solid rgba($primary, 0.2) !important;
      box-shadow: inset 0 0 0 0px #fff,
        inset 0 0 0 calc(1px + 0px) hsla(0, 0%, 100%, 0.1), var(0, 0 0 #0000);
    }
  }

  // Desktop
  @media screen and (min-width: 640px) {
    .mat-mdc-card {
      .mat-mdc-card-content {
        padding: 2.5rem !important;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }
}
