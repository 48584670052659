@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");
    $primary : mat.get-color-from-palette($primary-palette, 500);

    // Global
    mat-form-field {
        --mdc-outlined-text-field-container-shape: 0.5rem; // Radius  
        --mdc-outlined-text-field-outline-color: #D1D5DB; // Border Color


        .mat-mdc-text-field-wrapper {
            background-color: #F9FAFB;
            border-radius: 0.5rem;
        }


        mat-icon {
            color: #9CA3AF;
        }

        mat-label {
            color: #9CA3AF;
            font-weight: 500;
        }

        &:hover {
            --mdc-outlined-text-field-hover-outline-color: $primary; // Border Color on Hover //<- BUG not working still black
        }

        .mat-mdc-form-field-subscript-wrapper {
            background-color: transparent;
        }

        &.mat-form-field-disabled {
            cursor: not-allowed;


        }
    }





    // Theme

    .light {}

    .dark {
        mat-form-field {
            --mdc-outlined-text-field-outline-color: #4b5563; // Border Color

            .mat-mdc-text-field-wrapper {
                background-color: #374151;
            }

            mat-icon,
            mat-label {
                color: #9CA3AF;
            }

            &.mat-form-field-disabled {
                .mat-mdc-text-field-wrapper {
                    background-color: #0f172a !important;
                }
            }
        }
    }
}



@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}