@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");
    $accent-palette: map.get($color-config, "accent");
    $accent : mat.get-color-from-palette($accent-palette, 500);



    .dark mat-snack-bar-container {
        --mdc-snackbar-container-color: #0f172a;
        --mdc-snackbar-supporting-text-color: white;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;

        button span {
            color: $accent !important;
        }
    }


}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}