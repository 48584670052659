@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");


    .light .mat-mdc-button-base,
    .dark .mat-mdc-button-base {
        padding: 22px 24px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
    }

    .glass-round-btn {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid #fff;
        border-radius: 999px;

        &:hover {
            background-color: rgba(155, 217, 255, 0.3);
        }
    }

    .light,
    .dark {
        .mat-accent {
            --mdc-filled-button-label-text-color: #fff !important;
        }
    }

    // Theme
    .dark {

        span.mdc-button__label,
        span {
            color: #fff !important;
        }

    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}