@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, "primary");
  $primary : mat.get-color-from-palette($primary-palette, 500);
  $warn-palette: map.get($color-config, "warn");


  .mat-mdc-standard-chip {
    padding: 16px 8px;
    background-color: white;
    border: 1px solid #D1D5DB;
    border-radius: 999px;
  }



  // Theme
  .light {
    .mat-mdc-standard-chip {
      --mdc-chip-elevated-container-color: white;

      span {
        --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
      }

      mat-icon {
        color: #000;

      }
    }

    // Glass Style
    .glass-chip {

      .mat-mdc-standard-chip {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.3);

        color: #000;

        span {
          //    --mdc-chip-label-text-color: rgba(255, 255, 255, 0.87);
        }

        mat-icon {
          // color: rgba(255, 255, 255, 0.87);
          //
        }

      }
    }

  }

  .dark {
    .mat-mdc-standard-chip {
      border: 1px solid #4b5563;

      span {
        --mdc-chip-label-text-color: rgba(255, 255, 255, 0.87);
      }

      mat-icon {
        color: rgba(255, 255, 255, 0.87);

      }

    }

    // Glass Style
    .glass-chip {

      .mat-mdc-standard-chip {
        border: 1px solid rgba(255, 255, 255, 0.3);
        background-color: rgba(255, 255, 255, 0.3);

        span {
          //    --mdc-chip-label-text-color: rgba(255, 255, 255, 0.87);
        }

        mat-icon {
          // color: rgba(255, 255, 255, 0.87);
          //
        }

      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }
}