// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@use "theme-color.scss" as custom-theme;
@use "sass:map";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use "components/button-theme" as custom-mat-button;
@use "components/card-theme" as custom-mat-card;
@use "components/badge-theme" as custom-mat-badge;
@use "components/chip-theme" as custom-mat-chip;
@use "components/toolbar-theme" as custom-mat-toolbar;
@use "components/menu-theme" as custom-mat-menu;
@use "components/form-field-theme" as custom-mat-form-field;
@use "components/level-theme" as custom-level-status;
@use "components/snackbar-theme" as custom-mat-snackbar;
@include mat.core();

/* ---------------- Custom Font  ---------------- */

// https://github.com/angular/components/issues/6244

/* ---------------- / Custom Font  ---------------- */

// Set Primary / Accent / Warn from custom colors set in themes/theme.scsss
$primary: mat.define-palette(custom-theme.$primary);
$accent: mat.define-palette(custom-theme.$accent);
$warn: mat.define-palette(custom-theme.$warn);

// Set Global Font
$custom-typography: mat.define-typography-config($font-family: "Urbanist",
  );
@include mat.all-component-typographies($custom-typography);

/* -------------------------------- Custom Theme -------------------------------- */
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// LIGHT THEME
$light-theme: mat.define-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
      //  typography: $custom-typography,
      density: -3 // Default 0 ( Button height ... ),,,,,,,,,,
    ));

// DARK THEME
$dark-theme: mat.define-dark-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
        // background: $mat.get-color-from-palette($primary, 700),
        foreground: mat.get-color-from-palette($primary, 700),
        // Adjust the contrast value as needed
      ),
      //   typography: $custom-typography,
      density: -3 // Default 0,( Button height ... ),,,,,,,,,,
    ));

// Background palette for dark themes.
$custom-background: (
  // status-bar: red,
  app-bar: #1e293b,
  background: #0f172a,
  // hover: red,
  card: #1e293b,
  dialog: #1e293b,
  // raised-button: red,
  // selected-button: red,
  //  selected-disabled-button: red,
  //  disabled-button: red,
  //focused-button: red,
  // disabled-button-toggle: red,
  //  unselected-chip: red,
  //  disabled-list-option: red,
  // tooltip: red,
);

$color-map: map.get($dark-theme, "color");
$modified-color-map: map.merge($color-map,
    ("background": $custom-background,
    ));
$dark-theme: map.merge($dark-theme,
    ("color": $modified-color-map,
    ));

/* -------------------------------- / Custom Theme -------------------------------- */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.light {
  @include mat.core-theme($light-theme);
  @include mat.all-component-themes($light-theme);

  a {
    //  color: #000;
  }

  /*
  a:hover {
    color: mat.get-color-from-palette(custom-theme.$primary, 500);
  }*/

  /* ---- Markedown ---- */
  pre[class*="language-"] {
    background: #fff !important;
    box-shadow: inset 0 0 0 1px #eaecf0;
    border: 0px !important;
    margin: 0px;
    border-radius: 8px;
  }

  code[class*="language-"] {
    text-shadow: none !important;
    color: #333;

    .token.punctuation {
      color: #64748b;
    }

    .token.attr-name {
      color: mat.get-color-from-palette(custom-theme.$accent, 500);
    }

    .token.attr-value {
      color: mat.get-color-from-palette(custom-theme.$primary, 500);
    }

    .token.tag {
      color: #f472b6;
    }
  }
}

.dark {
  @include mat.core-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);

  // BUG: I don't know why but foreground text not work so I have to do that
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  span,
  label,
  a,
  p,
  i {
    color: #9ea9b8; // #64748b;
  }

  /*
  a:hover {
    color: mat.get-color-from-palette(custom-theme.$warn, 500);
  }*/

  /* ---- Markedown ---- */
  pre[class*="language-"] {
    background: #12193c !important;
    box-shadow: inset 0 0 0 1px #ffffff1a;
    border: 0px !important;
    margin: 0px;
    border-radius: 8px;
  }

  code[class*="language-"] {
    text-shadow: none !important;

    .token.punctuation {
      color: #ffffff56;
    }

    .token.attr-name {
      color: #cbd5e1;
    }

    .token.attr-value {
      color: #7dd3fc;
    }

    .token.tag {
      color: #f472b6;
    }
  }
}

/* -------------------------------- Custom Componants Style -------------------------------- */
@include custom-mat-button.theme($light-theme);
@include custom-mat-card.theme($light-theme);
@include custom-mat-badge.theme($light-theme);
@include custom-mat-chip.theme($light-theme);
@include custom-mat-toolbar.theme($light-theme);
@include custom-mat-menu.theme($light-theme);
@include custom-mat-form-field.theme($light-theme);
@include custom-level-status.theme($light-theme);
@include custom-mat-snackbar.theme($light-theme);
/* -------------------------------- / Custom Componants Style -------------------------------- */

/* -------------------------------- Typography Componants Style -------------------------------- */
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  /*
    font: 700 24px / 32px Urbanist;
    letter-spacing: normal;
    margin: inherit*/
}

/* -------------------------------- / Typography Componants Style -------------------------------- */

/* -------------------------------- Custom Class Style -------------------------------- */

.dark {

  // Answer Question
  .good-answer {
    // background-color: mat.get-color-from-palette($accent, 500) !important;
    background-color: #70BF85 !important;
    color: #fff !important;

    *,
    span {
      color: #fff !important;
    }
  }

  .bad-answer {
    background-color: #a50113 !important;
    color: #fff !important;

    *,
    span {
      color: #fff !important;
    }

    .user-value {
      text-decoration: line-through;
    }
  }

  .disable-answer {
    background-color: #bdbdbd !important;
    color: #fff !important;
    cursor: not-allowed !important;
    opacity: 0.25;

    *,
    span {
      color: #fff !important;
    }
  }
}

.light {

  // Answer Question
  .good-answer {
    // background-color: mat.get-color-from-palette($accent, 500) !important;
    background-color: #70BF85 !important;
    color: rgba(0, 0, 0, 0.5) !important;

    *,
    span {
      color: #000 !important;
    }
  }

  .bad-answer {
    background-color: mat.get-color-from-palette($warn, 500) !important;
    color: #000 !important;

    *,
    span {
      color: #000 !important;
    }

    .user-value {
      text-decoration: line-through;
    }
  }

  .disable-answer {
    background-color: #bdbdbd !important;
    color: #000 !important;
    cursor: not-allowed !important;
    opacity: 0.25;

    *,
    span {
      color: #000 !important;
    }
  }
}

// Color
.light .mat-primary-color,
.dark .mat-primary-color {
  color: mat.get-color-from-palette($primary, 500) !important;
}

.light .mat-accent-color,
.dark .mat-accent-color {
  color: mat.get-color-from-palette($accent, 500) !important;
}

.light .mat-warn-color,
.dark .mat-warn-color {
  color: mat.get-color-from-palette($warn, 500) !important;
}

// Background Color
.light .mat-primary-background,
.dark .mat-primary-background {
  background-color: mat.get-color-from-palette($primary, 500);
}

.light .mat-primary-background-pastel,
.dark .mat-primary-background-pastel {
  background-color: rgba(mat.get-color-from-palette($primary, 500), 0.1);
}

.light .mat-primary-background-pastel-3,
.dark .mat-primary-background-pastel-3 {
  background-color: rgba(mat.get-color-from-palette($primary, 500), 0.3);
}

.light .mat-primary-background-pastel-4,
.dark .mat-primary-background-pastel-4 {
  background-color: rgba(mat.get-color-from-palette($primary, 500), 0.4);
}

.light .mat-primary-background-pastel-5,
.dark .mat-primary-background-pastel-5 {
  background-color: rgba(mat.get-color-from-palette($primary, 500), 0.5);
}

.light .mat-accent-background,
.dark .mat-accent-background {
  background-color: mat.get-color-from-palette($accent, 500);
}

.light .mat-accent-background-pastel,
.dark .mat-accent-background-pastel {
  position: relative;
}

.light .mat-accent-background-pastel:before,
.dark .mat-accent-background-pastel:before {
  background-color: mat.get-color-from-palette($accent, 500);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 8px;
}

.light .mat-accent-background-pastel-3,
.dark .mat-accent-background-pastel-3 {
  background-color: rgba(mat.get-color-from-palette($accent, 500), 0.3);
}

.light .mat-warn-background,
.dark .mat-warn-background {
  background-color: mat.get-color-from-palette($warn, 500);
}

.light .mat-warn-background-pastel,
.dark .mat-warn-background-pastel {
  background-color: rgba(mat.get-color-from-palette($warn, 500), 0.1);
}

.light .mat-warn-background-pastel-3,
.dark .mat-warn-background-pastel-3 {
  background-color: rgba(mat.get-color-from-palette($warn, 500), 0.3);
}

// Border Color
.light .mat-primary-border-color,
.dark .mat-primary-border-color {
  border-color: mat.get-color-from-palette($primary, 500) !important;
}

.light .mat-accent-border-color,
.dark .mat-accent-border-color {
  border-color: mat.get-color-from-palette($accent, 500);
}

// Shadow Color
.light .mat-primary-shadow-color,
.dark .mat-primary-shadow-color {
  box-shadow: inset 0 0 0 1px mat.get-color-from-palette($primary, 500);
}

.light .mat-accent-shadow-color,
.dark .mat-accent-shadow-color {
  box-shadow: inset 0 0 0 1px mat.get-color-from-palette($accent, 500);
}

.text-grey {
  color: #6b7280;
}

// Sidenav - Active Link
.active-list-item {
  background-color: rgba(mat.get-color-from-palette($primary, 500),
      0.2) !important;

  div,
  svg {
    color: mat.get-color-from-palette($primary, 500) !important;
    font-weight: 600;
  }
}

.active-list-item-v2 {
  background-color: rgba(mat.get-color-from-palette($primary, 500),
      0.2) !important;
  border-radius: 0px !important;

  .mat-mdc-list-item-title {
    color: mat.get-color-from-palette($primary, 500);
  }

  .mat-icon-wrapper {
    // background-color: mat.get-color-from-palette($primary, 500) !important;

    mat-icon {
      color: mat.get-color-from-palette($primary, 500) !important;
    }
  }
}

.active-list-item-v3 {
  color: mat.get-color-from-palette($accent, 500) !important;
}

/* -------------------------------- / Custom Class Style -------------------------------- */

// FIX BUG Line Middle in input beacuase of tailwind import
.mdc-notched-outline__notch {
  border-right: none;
}
