@import url("tailwind.css");
@import "themes/theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* Typography */
.mat-typography {
  h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
  }

  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 111.111% */
  }

  h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    /* 120% */
  }

  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
  }

  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
  }

  h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
  }
}

.formly-wrapper-mat-form-field {
  width: 100%;
}

.mat-green-color {
  color: #70bf85;
}

.mat-green-background-pastel {
  background-color: rgba(#70bf85, 0.2) !important;
}

.mat-grey-color {
  color: #667085;
}

.mat-grey-background-pastel {
  background-color: rgba(#667085, 0.2) !important;
}

.mat-grey-background-light-pastel {
  background-color: rgba(#667085, 0.1) !important;
}

.mat-warning-color {
  color: #fd7e14;
}

.mat-warning-background-light-pastel {
  background-color: rgba(#fd7e14, 0.1) !important;
}

/* Animation de secousse */
.bell {
  transition: transform 0.3s ease;
}

.bell-animate {
  animation: ring-bell 0.5s ease-in-out;
}

/* Définition de l'animation de la cloche */
@keyframes ring-bell {
  0% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-3px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0);
  }
}



/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color:var(--mat-sidenav-content-background-color);
  width:16px
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color:var(--mat-sidenav-content-background-color);
}
::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid var(--mat-sidenav-content-background-color);
}
::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}
