@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");

    .mat-toolbar {
        --mat-toolbar-standard-height: 60px;
        --mat-toolbar-mobile-height: 60px;
    }

    .light .mat-toolbar.mat-toolbar-white {
        --mat-toolbar-container-background-color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);


        mat-icon {
            color: #6b7280;
        }

    }

    .dark {
        .mat-toolbar {
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);

        }
    }

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}
