@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $primary: mat.get-color-from-palette($primary-palette, 500);
  $primary-lite: mat.get-color-from-palette($primary-palette, 300);
  $accent: mat.get-color-from-palette($accent-palette, 500);
  $accent-lite: mat.get-color-from-palette($accent-palette, 300);

  // Custom Class - Level unlocked
  .light .level-unlocked,
  .dark .level-unlocked {
    background-color: $primary;
  }

  // Custom Class - Level locked
  .light .level-locked,
  .dark .level-locked {
    background-color: var(--Disabled, #d9d9d9);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }
}
