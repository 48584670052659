/* ---------------- Custom Palette ---------------- */
/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */


:root {

  // Primary
  --primary-50: #e7e5f0;
  --primary-100: #c4bfd9;
  --primary-200: #9d95bf;
  --primary-300: #766aa5;
  --primary-400: #584a92;
  --primary-500: #3b2a7f;
  --primary-600: #352577;
  --primary-700: #2d1f6c;
  --primary-800: #261962;
  --primary-900: #190f4f;
  --primary-A100: #9687ff;
  --primary-A200: #6954ff;
  --primary-A400: #3c21ff;
  --primary-A700: #2608ff;

  --primary-contrast-50: #000;
  --primary-contrast-100: #000;
  --primary-contrast-200: #000;
  --primary-contrast-300: #fff;
  --primary-contrast-400: #fff;
  --primary-contrast-500: #fff;
  --primary-contrast-600: #fff;
  --primary-contrast-700: #fff;
  --primary-contrast-800: #fff;
  --primary-contrast-900: #fff;
  --primary-contrast-A100: #000;
  --primary-contrast-A200: #fff;
  --primary-contrast-A400: #fff;
  --primary-contrast-A700: #fff;


  // Accent
  --accent-50: #e0f9f4;
  --accent-100: #b3efe3;
  --accent-200: #80e4d1;
  --accent-300: #4dd9be;
  --accent-400: #26d1b0;
  --accent-500: #00c9a2;
  --accent-600: #00c39a;
  --accent-700: #00bc90;
  --accent-800: #00b586;
  --accent-900: #00a975;
  --accent-A100: #d3ffef;
  --accent-A200: #a0ffdd;
  --accent-A400: #6dffcb;
  --accent-A700: #53ffc2;

  --accent-contrast-50: #000;
  --accent-contrast-100: #000;
  --accent-contrast-200: #000;
  --accent-contrast-300: #000;
  --accent-contrast-400: #000;
  --accent-contrast-500: #000;
  --accent-contrast-600: #000;
  --accent-contrast-700: #fff;
  --accent-contrast-800: #fff;
  --accent-contrast-900: #fff;
  --accent-contrast-A100: #000;
  --accent-contrast-A200: #000;
  --accent-contrast-A400: #000;
  --accent-contrast-A700: #000;

  // Warn
  --warn-50: #f4e1e3;
  --warn-100: #e4b3b8;
  --warn-200: #d28089;
  --warn-300: #c04d5a;
  --warn-400: #b32736;
  --warn-500: #a50113;
  --warn-600: #9d0111;
  --warn-700: #93010e;
  --warn-800: #8a010b;
  --warn-900: #790006;
  --warn-A100: #ffa7a8;
  --warn-A200: #ff7475;
  --warn-A400: #ff4143;
  --warn-A700: #ff2729;

  --warn-contrast-50: #000;
  --warn-contrast-100: #000;
  --warn-contrast-200: #000;
  --warn-contrast-300: #fff;
  --warn-contrast-400: #fff;
  --warn-contrast-500: #fff;
  --warn-contrast-600: #fff;
  --warn-contrast-700: #fff;
  --warn-contrast-800: #fff;
  --warn-contrast-900: #fff;
  --warn-contrast-A100: #000;
  --warn-contrast-A200: #000;
  --warn-contrast-A400: #fff;
  --warn-contrast-A700: #fff;

}

$primary: (
  50 : var(--primary-50),
  100 : var(--primary-100),
  200 : var(--primary-200),
  300 : var(--primary-300),
  400 : var(--primary-400),
  500 : var(--primary-500),
  600 : var(--primary-600),
  700 : var(--primary-700),
  800 : var(--primary-800),
  900 : var(--primary-900),
  A100 : var(--primary-A100),
  A200 : var(--primary-A200),
  A400 : var(--primary-A400),
  A700 : var(--primary-A700),
  contrast: (50 : var(--primary-contrast-50),
    100 : var(--primary-contrast-100),
    200 : var(--primary-contrast-200),
    300 : var(--primary-contrast-300),
    400 : var(--primary-contrast-400),
    500 : var(--primary-contrast-500),
    600 : var(--primary-contrast-600),
    700 : var(--primary-contrast-700),
    800 : var(--primary-contrast-800),
    900 : var(--primary-contrast-900),
    A100 : var(--primary-contrast-A100),
    A200 : var(--primary-contrast-A200),
    A400 : var(--primary-contrast-A400),
    A700 : var(--primary-contrast-A700),
  )
);




$accent: (
  50 : var(--accent-50),
  100 : var(--accent-100),
  200 : var(--accent-200),
  300 : var(--accent-300),
  400 : var(--accent-400),
  500 : var(--accent-500),
  600 : var(--accent-600),
  700 : var(--accent-700),
  800 : var(--accent-800),
  900 : var(--accent-900),
  A100 : var(--accent-A100),
  A200 : var(--accent-A200),
  A400 : var(--accent-A400),
  A700 : var(--accent-A700),
  contrast: (50 : var(--accent-contrast-50),
    100 : var(--accent-contrast-100),
    200 : var(--accent-contrast-200),
    300 : var(--accent-contrast-300),
    400 : var(--accent-contrast-400),
    500 : var(--accent-contrast-500),
    600 : var(--accent-contrast-600),
    700 : var(--accent-contrast-700),
    800 : var(--accent-contrast-800),
    900 : var(--accent-contrast-900),
    A100 : var(--accent-contrast-A100),
    A200 : var(--accent-contrast-A200),
    A400 : var(--accent-contrast-A400),
    A700 : var(--accent-contrast-A700),
  )
);

$warn: (
  50 : var(--warn-50),
  100 : var(--warn-100),
  200 : var(--warn-200),
  300 : var(--warn-300),
  400 : var(--warn-400),
  500 : var(--warn-500),
  600 : var(--warn-600),
  700 : var(--warn-700),
  800 : var(--warn-800),
  900 : var(--warn-900),
  A100 : var(--warn-A100),
  A200 : var(--warn-A200),
  A400 : var(--warn-A400),
  A700 : var(--warn-A700),
  contrast: (50 : var(--warn-contrast-50),
    100 : var(--warn-contrast-100),
    200 : var(--warn-contrast-200),
    300 : var(--warn-contrast-300),
    400 : var(--warn-contrast-400),
    500 : var(--warn-contrast-500),
    600 : var(--warn-contrast-600),
    700 : var(--warn-contrast-700),
    800 : var(--warn-contrast-800),
    900 : var(--warn-contrast-900),
    A100 : var(--warn-contrast-A100),
    A200 : var(--warn-contrast-A200),
    A400 : var(--warn-contrast-A400),
    A700 : var(--warn-contrast-A700),
  )
);

/* ---------------- / Custom Palette ---------------- */